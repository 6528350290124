/**
Tailwind specific imports.
These are needed to import the tailwind classes into the project.
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3 {
	@apply text-primary;
	@apply text-left;
	@apply font-bold;
	@apply font-sans;
	@apply m-4;
	@apply cursor-default;
}
h1 {
	@apply text-4xl;
}
h2 {
	@apply text-xl;
}
h3 {
	@apply my-2;
	@apply text-xl;
}
h4,
h5 {
	@apply text-base;
	@apply text-primary;
	@apply text-left;
	@apply font-bold;
	@apply font-sans;
	@apply m-2;
	@apply cursor-default;
}
p,
div {
	@apply text-primary;
	@apply font-sans;
	@apply text-base;
	@apply cursor-default;
}
