:root {
	—-app-height: 100%;
}
html,
body {
	padding: 0;
	margin: 0;
	width: 100vw;
	height: 100vh;
	height: var(--app-height);
}

.MuiBox-root {
	box-sizing: border-box;
}
